/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { graphql, useStaticQuery, Script } from 'gatsby'


const SEO = ({ title, metaTitle, description, image, url, meta, hrefLang }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE



  const globalSeo = useStaticQuery(
    graphql`
      {
        en: sanityGlobalSeo {
          title
          titleSection
          description
          url
          lang
          ogImage {
            asset {
              url
            }
          }
        }
        it: sanityGlobalSeo(i18n_lang: {eq: "it"}) {
          title
          titleSection
          description
          url
          lang
          ogImage {
            asset {
              url
            }
          }
        }
      }
    `
  )

  const seo = language === 'en' ? globalSeo.en : globalSeo.it

  const fallbackDescription = language === 'en' ? `The ${title} page on Soul Valley` : `La pagina ${title} di Soul Valley`

  const formattedTitle =
    metaTitle || `${title} | ${seo.titleSection} | ${seo.title}`
  const metaDescription = description || fallbackDescription
  const metaImage = image || seo.ogImage.asset?.url
  const lang = seo.lang || 'en'

  const domain = language === 'en' ? 'https://soulvalley.com' : 'http://soulvalley.it' 


  return (
    <>
      <Helmet prioritizeSeoTags>
        <html lang={language} />
        <title>{formattedTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={metaImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${domain}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${domain}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${domain}/favicon-16x16.png`} />
        <link rel="manifest" href={`${domain}/site.webmanifest`} />
        <link rel="mask-icon" href={`${domain}/safari-pinned-tab.svg`} color="#49717f" />
        <meta name="apple-mobile-web-app-title" content="Soul Valley" />
        <meta name="application-name" content="Soul Valley" />
        <meta name="msapplication-TileColor" content="#49717f" />
        <meta name="theme-color" content="#49717f" />
        <meta property="og:image" content={`${domain}/main-open-graph.png`} />
        <meta name="facebook-domain-verification" content="kqhfiq29tp20rkf3mq9glw0ouk9bv2" />



        {hrefLang && hrefLang.map(item => {
          return <link rel="alternate" href={item.href} hreflang={item.hreflang} key={item.lang} />
          }
        )}
      </Helmet>

      <Script id="facebook-pixel">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2285364378530647');
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        <img 
          height="1" 
          width="1" 
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=2285364378530647&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
